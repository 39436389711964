import React from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedSticker } from "../store/imageHandling";
import reactImageSize from "react-image-size";

const StickersPanelStyled = styled.div({
    marginTop: "30px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
});

const StickerDiv = styled.div(({ mobileScreen }) => {
    return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: mobileScreen ? "75px" : "150px",
        height: mobileScreen ? "75px" : "150px",
        padding: "20px 20px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "grey",
            borderRadius: "10px",
        },
    };
});

const MyImage = styled.img({
    maxWidth: "100%",
    maxHeight: "100%",
});

const arr = [
    "CautionCone.png",
    "CautionTape.png",
    "EveryDaySafe.gif",
    "EveryDaySafe.png",
    "FamilyFirst.png",
    "safety-first.gif",
    //"2023-SAM-logo.png",
    //"SAM_Logo.gif",
    "SAM-Logo.png",
    "STE_2.png",
    "STE_Logo.gif",
    "bomb.gif",
    //"InFocus.gif",
    "JobWellDone.png",
    "World'sGreatest.gif",
    "YouAreAppreciated.png",
    "Gloves.png",
    "Grip&Grab.gif",
    "Knick.gif",
    "LiftIt.png",
    "PartnerUp.png",
    "SafetyKnife.png",
    "ZoneOfSafety.gif",
    //"CaughtOrangeHanded.png",
    "MHE_Lift_Type.gif",
    "Confetti.gif",
    "GotYourBack.gif",
    "Buckle_Up_Safety_Sign.png",
    "BuckleUp.gif",
    "ES_Van.png",
    "RE_Truck.png",
    "LEO.gif",
];

//const getCurrentDate = (separator = "-") => {
//    let newDate = new Date();
//    let date = newDate.getDate();
//    let month = newDate.getMonth() + 1;
//    let year = newDate.getFullYear();
//
//    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;
//};

function StickersPanel({ toggleDrawer }) {
    const dispatch = useDispatch();
    const mobileScreen = useSelector((state) => state.manage.mobileScreen);

    const handleSelect = async (image) => {
        try {
            const { width, height } = await reactImageSize("../assets/" + image);
            const regex = /^.+\.(.+)$/;
            const extension = image.match(regex)[1].toLowerCase();
            const obj = {
                id: Date.now(),
                name: image,
                width,
                height,
                type: extension,
            };
            dispatch(addSelectedSticker(obj));
            toggleDrawer(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <StickersPanelStyled>
            {arr.map((image) => (
                <StickerDiv mobileScreen={mobileScreen} key={image}>
                    <MyImage src={"../assets/" + image} onClick={() => handleSelect(image)} draggable="false" />
                </StickerDiv>
            ))}
        </StickersPanelStyled>
    );
}

export default StickersPanel;
